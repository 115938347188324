//
//
//
//
//
//
//

import trainer from '../components/Trainer'

export default {
  components: {
    trainer,
  },
  data: () => ({
    loaded: false,
    ids: [],
  }),
  created() {
    this.loadVocab()
  },
  methods: {
    async loadVocab() {
      const { rows } = await this.$bucket.db.allDocs({
        startkey: `00001:`,
        endkey: `99999:\uffff`,
      })
      this.start(rows.map(row => row.id))
    },
    start(ids) {
      this.ids.push(...this.shuffle(ids))
      this.selecting = false
      this.loaded = true
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ]
      }

      return array
    },
  },
}
