//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ids: {
      type: Array,
      required: true,
    },
    ready: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    term: {},
    index: 0,
    hint: false,
    revealed: false,
    trans: false,
    loaded: false,
    hasSpeech: !!window.SpeechSynthesisUtterance,
  }),
  computed: {
    wordHint() {
      if (!this.term._id) return
      let words = this.term.english.split(` `)
      for (let word of words) {
        let hintWord = Array.from(word)
        let h = hintWord.shift()
        for (let letter of hintWord) {
          if (!/[A-Za-z]/.test(letter)) h += letter
          else h += `·`
        }
        words[words.indexOf(word)] = h
      }
      return words.join(` `)
    },
    cardSize() {
      let h, w
      switch (this.$vuetify.breakpoint.name) {
        case `md`:
          w = 475
          h = 235
          break
        case `xs`:
          w = 340
          h = 235
          break
        case `sm`:
          w = 400
          h = 235
          break
        default:
          w = 475
          h = 235
      }
      return { h, w }
    },
  },
  async beforeMount() {
    const doc = await this.$bucket.db.get(this.ids[0])
    this.term = Object.assign({}, doc)
    this.loaded = true
    this.trans = false
  },
  methods: {
    showHint() {
      this.hint = true
      umami(`Show hint`)
    },
    reveal() {
      umami(`Reveal word`)
      this.revealed = true
    },
    reset(nolog) {
      !nolog && umami(`Reset letter`)
      this.hint = false
      this.revealed = false
    },
    async next() {
      this.trans = true
      if (this.index + 1 === this.ids.length) this.index = 0
      else this.index++
      umami(`Next word`)
      await this.getTerm()
    },
    async previous() {
      if (!this.index) return
      this.trans = true
      this.index--
      umami(`Previous word`)
      await this.getTerm()
    },
    getTerm() {
      this.reset(1)
      Promise.all([
        this.$bucket.db.get(this.ids[this.index]).then(doc => {
          this.term = Object.assign({}, doc)
        }),
        new Promise(resolve => setTimeout(resolve, 300)),
      ]).then(() => (this.trans = false))
    },
    sayWord() {
      umami(`Pronounce word`)
      const utterance = new SpeechSynthesisUtterance(this.term.russian)
      utterance.lang = `ru-RU`
      speechSynthesis.speak(utterance)
    },
  },
}
